import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const StyledMenuMobile = styled.div`
  position: fixed;
  z-index: ${({ isOpen }) => (isOpen ? 3 : 2)};
  width: 100%;
  left: 0;
`;

const StyledTrigger = styled.a`
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.04);
  width: 3.71428571em;
  height: 3.71428571em;
  border-radius: 50%;
  text-align: center;
  background: transparent;
  display: inline-block;
  transition: 0.2s ease;
  margin-left: 20px;
  cursor: pointer;

  &:after {
    content: '';
    width: 3.71428571em;
    height: 3.71428571em;
    background: #fff;
    position: absolute;
    border-radius: 50%;
    left: 20px;
    transition: transform 0.5s ease-in-out;
    transform: scale(1);
    ${({ isOpen }) => (isOpen ? 'transform: scale(60)' : 'transform: scale(1)')};
  }

  &:hover {
    box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
  }
`;

const StyledIcon = styled.span`
  font-size: 1.78571429em;
  position: relative;
  top: 13px;
  color: #252525;
  z-index: 2;
`;

const fadeDisplay = keyframes`
  0%{
    opacity: 0;
    transform: scale(.98) translateY(-.6em);
  }
  
  100%{
    opacity: 1;
    transform: scale(1) translateY(0);
  }
`;

const StyledMenu = styled.div`
  position: relative;
  animation: ${fadeDisplay} 0.4s cubic-bezier(0.73, 0.005, 0.22, 1);
  ${({ isOpen }) => !isOpen && 'display: none'};
`;

const StyledMenuBody = styled.div`
  min-height: calc(100vh - 150px);

  ul {
    margin-left: 35px;
  }

  li {
    text-align: left;
    margin-top: 13px;
    margin-bottom: 13px;
  }

  span {
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    z-index: 1;
    font-weight: bold;
    color: #666666;

    &:after {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      transform: scaleX(1);
      display: block;
      left: 0;
      top: auto;
      bottom: 7px;
      z-index: -1;
      background: #ff1053;
    }
  }

  a {
    font-size: 18px;
    font-weight: 600;
    color: #333333 !important;
  }

  .download-catalog {
    display: flex;
    align-items: center;

    i {
      font-width: bold;
    }

    small {
      color: #ccc;
      margin-left: 5px;
    }
  }
`;

const Contact = styled.div`
  position: absolute;
  top: 5px;
  right: 20px;

  a {
    color: #333 !important;
    font-weight: bold;
    padding: 5px 15px;
    background: #fff;
    border: 0 !important;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.04);

    ${({ isOpen }) =>
      isOpen &&
      css`
        background: #ff4f0f;
        background: linear-gradient(125deg, #ff4f0f, #ff1053);
        color: white !important;

        i {
          color: white !important;
        }
      `}
  }
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;

  a {
    display: inline-block;
  }

  .gatsby-image-wrapper {
    width: 38px;
    margin: 0;
    position: relative;
    top: 4px;
  }

  ul {
    margin-top: 40px;

    li {
      display: inline-block;
      margin-right: 40px;

      &:last-child {
        margin-right: 0;
      }

      a {
        color: #666666;
      }

      .icon {
        font-size: 20px;
      }
    }
  }
`;

const MenuMobile = ({ services }) => {
  const data = useStaticQuery(graphql`
    query catalogAsset {
      allContentfulAsset(filter: { title: { eq: "catalogo" } }) {
        nodes {
          file {
            url
          }
        }
      }

      allFile(filter: { name: { eq: "alm_logo-min" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `);
  const [file] = data.allContentfulAsset.nodes;
  const [isOpen, setIsOpen] = useState(false);
  const [logoNode] = data.allFile.edges;

  return (
    <StyledMenuMobile isOpen={isOpen}>
      <StyledTrigger isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? (
          <StyledIcon>X</StyledIcon>
        ) : (
          <StyledIcon className="stack-interface stack-menu" />
        )}
      </StyledTrigger>

      <Contact isOpen={isOpen}>
        <Link to={'/contacto'} className="btn">
          Contacto
        </Link>
      </Contact>

      <StyledMenu isOpen={isOpen}>
        <StyledMenuBody>
          <ul>
            <li>
              <Link to="/" title="Página de inicio" onClick={() => setIsOpen(false)}>
                Inicio
              </Link>
            </li>
            <li>
              <a
                href={file.file.url}
                title="Catálogo"
                target="_blank"
                onClick={() => setIsOpen(false)}
                className="download-catalog"
              >
                <i className="icon-Download-fromCloud mr-2" />
                Catálogo
                <small>(pdf)</small>
              </a>
            </li>
            <li>
              <span>Servicios</span>
            </li>
            {services.map((service, key) => (
              <li key={key}>
                <Link
                  to={`/${service.slug}`}
                  title={service.title}
                  onClick={() => setIsOpen(false)}
                >
                  {service.title}
                </Link>
              </li>
            ))}
          </ul>
        </StyledMenuBody>

        <Footer>
          <Link to="/">
            <Img fluid={logoNode.node.childImageSharp.fluid} alt="Logo ALM Print" />
          </Link>

          <ul>
            <li>
              <a
                href="https://www.instagram.com/alm.print"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="socicon socicon-instagram icon " />
              </a>
            </li>

            <li>
              <a
                href="https://www.facebook.com/almprint.es"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="socicon socicon-facebook icon " />
              </a>
            </li>

            <li>
              <a
                href="https://www.linkedin.com/company/almprint"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="socicon socicon-linkedin icon" />
              </a>
            </li>
          </ul>
        </Footer>
      </StyledMenu>
    </StyledMenuMobile>
  );
};

export default MenuMobile;
