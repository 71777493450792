import React from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const StyledFooter = styled.footer`
  .logo-index {
    margin-right: 1.85714286em;
  }

  ${down('md')} {
    .logo-index {
      margin-right: 0;
      margin-bottom: 20px;
      display: inline-block;
    }

    .contact-email span {
      font-size: 14px;
      opacity: 1;
    }

    .social-list {
      text-align: center;

      .socicon {
        font-size: 20px;
        color: #666666;
      }
    }
  }
`;

const ContactUs = styled.div`
  .separator-contact {
    display: inline-block;
    margin: 0 15px;
    color: #acacac;
  }

  i {
    font-weight: bold;
  }

  .hidden-xs {
    display: inline-block;
  }
`;

const FooterLogo = styled.div`
  margin-right: 1.85714286em;

  a {
    display: block;
  }

  .gatsby-image-wrapper {
    width: 42px;
  }
`;

const ContactWrapper = styled.div`
  display: flex;
  align-items: center;

  ${down('md')} {
    flex-direction: column;

    ${FooterLogo} {
      margin-right: 0;
    }

    ${ContactUs} {
      margin-top: 20px;
      font-size: 14px;
    }
  }
`;

const Footer = () => {
  const data = useStaticQuery(graphql`
    query AssetFiles {
      allFile(filter: { name: { eq: "alm_logo-min" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `);
  const [logoNode] = data.allFile.edges;

  return (
    /*      <footer className="space--sm footer-2 bg--secondary">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-3 col-6">
              <h6 className="type--uppercase">Company</h6>
              <ul className="list--hover">
                <li>
                  <a href="#">About Company</a>
                </li>
                <li>
                  <a href="#">Our Team</a>
                </li>
                <li>
                  <a href="#">Locations</a>
                </li>
                <li>
                  <a href="#">History</a>
                </li>
                <li>
                  <a href="#">Work With Us</a>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-3 col-6">
              <h6 className="type--uppercase">Developers</h6>
              <ul className="list--hover">
                <li>
                  <a href="#">Developer Center</a>
                </li>
                <li>
                  <a href="#">API Reference</a>
                </li>
                <li>
                  <a href="#">Downloads</a>
                </li>
                <li>
                  <a href="#">Tools</a>
                </li>
                <li>
                  <a href="#">Developer Blog</a>
                </li>
                <li>
                  <a href="#">Developer Forums</a>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-3 col-6">
              <h6 className="type--uppercase">Support</h6>
              <ul className="list--hover">
                <li>
                  <a href="#">Help Center</a>
                </li>
                <li>
                  <a href="#">Live Chat</a>
                </li>
                <li>
                  <a href="#">Downloads</a>
                </li>
                <li>
                  <a href="#">Press Kit</a>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-3 col-6">
              <h6 className="type--uppercase">Locations</h6>
              <ul className="list--hover">
                <li>
                  <a href="#">Melbourne</a>
                </li>
                <li>
                  <a href="#">London</a>
                </li>
                <li>
                  <a href="#">New York</a>
                </li>
                <li>
                  <a href="#">San Francisco</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <span className="type--fine-print">
                &copy;
                <span className="update-year"></span> Stack Inc.
              </span>
              <a className="type--fine-print" href="#">
                Privacy Policy
              </a>
              <a className="type--fine-print" href="#">
                Legal
              </a>
            </div>
            <div className="col-md-6 text-right text-left-xs">
              <ul className="social-list list-inline list--hover">
                <li>
                  <a href="#">
                    <i className="socicon socicon-google icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="socicon socicon-twitter icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="socicon socicon-facebook icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="socicon socicon-instagram icon icon--xs"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      */

    <StyledFooter className="footer-3 text-center-xs space--xs ">
      {/*<section className="space--xs imagebg" data-gradient-bg='#9e178a,#d7172a,#ed801b,#f3b500,#74b422,#008b39,#008080,#0056a3'>*/}

      {/*<div className="container">*/}
      {/*<div className="cta cta--horizontal text-center-xs row">*/}
      {/*<div className="col-md-4">*/}
      {/*<h4>Let's get you started</h4>*/}
      {/*</div>*/}
      {/*<div className="col-md-5">*/}
      {/*<p className="lead">*/}
      {/*Start building beautiful pages in your browser*/}
      {/*</p>*/}
      {/*</div>*/}
      {/*<div className="col-md-3 text-right text-center-xs">*/}
      {/*<a className="btn btn--primary type--uppercase" href="">*/}
      {/*<span className="btn__text">*/}
      {/*Try Builder*/}
      {/*</span>*/}
      {/*</a>*/}
      {/*</div>*/}
      {/*</div>*/}
      {/*</div>*/}

      {/*</section>*/}

      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <ContactWrapper>
              <FooterLogo>
                <Link to="/">
                  <Img fluid={logoNode.node.childImageSharp.fluid} alt="Logo ALM Print" />
                </Link>
              </FooterLogo>

              <ContactUs>
                <Link to="/contacto" className="contact-email">
                  Contacta con nosotros
                </Link>

                <div className="hidden-xs">
                  <span className="separator-contact">-</span>
                  <a href="mailto:info@almprint.es">
                    <i className="icon-Email" />
                    {'  '}
                    info@almprint.es
                  </a>
                  <span className="separator-contact">-</span>
                  <a href="tel:918729809">
                    <i className="icon-Smartphone-4" />
                    {'  '} 918 72 98 09
                  </a>
                </div>
              </ContactUs>
            </ContactWrapper>

            {/*<ul className="list-inline">*/}
            {/*  <li className="list-inline-item">*/}
            {/*    <Link to="/contacto" className="contact-email">*/}
            {/*      Contacta con nosotros*/}
            {/*    </Link>*/}
            {/*  </li>*/}
            {/*</ul>*/}
          </div>
          <div className="col-md-6 text-right text-center-xs">
            <ul className="social-list list-inline list--hover">
              <li className="list-inline-item">
                <a
                  href="https://www.instagram.com/almprint.es/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Instagram"
                >
                  <i className="socicon socicon-instagram icon icon--xs" />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href="https://www.facebook.com/almprint.es"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Facebook"
                >
                  <i className="socicon socicon-facebook icon icon--xs" />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href="https://www.linkedin.com/company/almprint"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Linkedin"
                >
                  <i className="socicon socicon-linkedin icon icon--xs" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <p className="type--fine-print">Impresión digital y diseño gráfico</p>
          </div>
          <div className="col-md-6 text-right text-center-xs">
            <span className="type--fine-print">
              ©<span className="update-year">2020</span> ALM Print
            </span>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;
