import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import Navigation from '../Navigation';
import MenuMobile from '../MenuMobile';
import Img from 'gatsby-image';

const StyledNavMobile = styled.nav`
  z-index: 99;
  width: 100%;
  position: absolute;

  &.bar--transparent {
    .logo-dark {
      display: none;
    }
  }
`;

const LogosMobile = styled.div`
  text-align: center;
  margin-top: 5px;

  .gatsby-image-wrapper {
    width: 112px;
    margin: 0;
    position: relative;
    top: 4px;
  }

  a {
    position: relative;
    z-index: 2;
    display: inline-block;
  }
`;

const LogoDesktop = styled.div`
  .gatsby-image-wrapper {
    max-width: 112px;
  }
`;

const Header = ({ navBackground }) => {
  const data = useStaticQuery(graphql`
    query ContentFulAsset {
      allContentfulService {
        nodes {
          slug
          order
          title
          subTitle
        }
      }

      allFile(filter: { name: { eq: "alm_logo-light" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `);
  const nodeServices = data.allContentfulService.nodes;
  const [imageNode] = data.allFile.edges;

  const services = nodeServices.length && nodeServices.sort((a, b) => a.sort - b.sort);

  return (
    <>
      <StyledNavMobile className={`bar bar-2 visible-xs bar--${navBackground}`}>
        <MenuMobile services={services} />

        <LogosMobile>
          <Link to="/">
            <Img fluid={imageNode.node.childImageSharp.fluid} alt="Logo ALM Print" />
          </Link>
        </LogosMobile>
      </StyledNavMobile>

      <nav className={`bar bar-2 hidden-xs bar--${navBackground} bar--absolute`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-md-offset-0 col-4">
              <LogoDesktop className="bar__module">
                <Link to="/">
                  <Img fluid={imageNode.node.childImageSharp.fluid} alt="Logo ALM Print" />
                </Link>
              </LogoDesktop>
            </div>

            <div className="col-lg-10 col-md-12 text-right text-left-xs text-left-sm">
              <div className="bar__module">
                <Navigation services={services} />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
